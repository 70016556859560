const Services=()=>{
    return (
      <div id="services" className="services">
        <div className="container">
          {/*-728x90-*/}
          <div className="service-head one text-center ">
            <h4>WHAT I DO</h4>
            <h3>MY <span>SERVICES</span></h3>
            <span className="border two" />
          </div>
          {/* services-grids */}
          <div className="wthree_about_right_grids w3l-agile">
            <div className="col-md-6 wthree_about_right_grid">
              <div className="col-xs-4 wthree_about_right_grid_left">
                <div className="hvr-rectangle-in">
                  <i className="glyphicon glyphicon-pencil" />
                </div>
              </div>
              <div className="col-xs-8 wthree_about_right_grid_right">
                <h4>Web Design</h4>
                <p>
                  I can design the website using html,css,bootstrap,jquery.
                </p>
              </div>
              <div className="clearfix"> </div>
            </div>
            <div className="col-md-6 wthree_about_right_grid">
              <div className="col-xs-4 wthree_about_right_grid_left">
                <div className="hvr-rectangle-in">
                  <i className="glyphicon glyphicon-cog" />
                </div>
              </div>
              <div className="col-xs-8 wthree_about_right_grid_right">
                <h4>Web Developement</h4>
                <p>I can develope the website single handly.Using Php,html,css,jquery,bootstrap,React Js,Next Js.</p>
              </div>
              <div className="clearfix"> </div>
            </div>
            <div className="col-md-6 wthree_about_right_grid">
              <div className="col-xs-4 wthree_about_right_grid_left">
                <div className="hvr-rectangle-in">
                  <i className="glyphicon glyphicon-leaf" />
                </div>
              </div>
              <div className="col-xs-8 wthree_about_right_grid_right">
                <h4>Backend Developement</h4>
                <p>I can work as a backend developer.With Core Php,Codeigniter,Laravel,node.js,express.js,<br/>MongoDB,Mysql,Neo4j.</p>
              </div>
              <div className="clearfix"> </div>
            </div>
            <div className="col-md-6 wthree_about_right_grid">
              <div className="col-xs-4 wthree_about_right_grid_left">
                <div className="hvr-rectangle-in">
                  <i className="glyphicon glyphicon-gift" />
                </div>
              </div>
              <div className="col-xs-8 wthree_about_right_grid_right">
                <h4>Mobile App</h4>
                <p>I can develope mobile app using React Native & Flutter.</p>
              </div>
              <div className="clearfix"> </div>
            </div>
            <div className="clearfix"> </div>
          </div>
          {/* services-grids */}
          {/*-728x90-*/}
        </div>
      </div>
     
      
      );

    
}
export default Services;