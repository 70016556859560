const ContactMe = () => {
  return (
    <div className="footer" id="contact">
      <div className="container">
        <div className="service-head one text-center">
          <h4>CONTACT ME</h4>
          <h3>
            GET <span>IN TOUCH WITH ME</span>
          </h3>
          <span className="border two" />
        </div>
        <div className="mail_us">
          <div className="col-md-6 mail_left">
            <div className="contact-grid1-left">
              <div className="contact-grid1-left1">
                <span
                  className="glyphicon glyphicon-envelope"
                  aria-hidden="true"
                />
                <h4>Contact By Email</h4>
                <ul>
                  <li>
   
                    <a href="mailto:rishitiwary694076@gmail.com">
                      <span
                        className="__cf_email__"
                      
                      >
                       rishitiwary694076@gmail.com
                      </span>
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
            <div className="contact-grid1-left">
              <div className="contact-grid1-left1">
                <span
                  className="glyphicon glyphicon-earphone"
                  aria-hidden="true"
                />
                <h4>Contact By Phone</h4>
                <ul>
                  <li>Phone: +919122694076</li>
                  
                </ul>
              </div>
            </div>
            <div className="contact-grid1-left">
              <div className="contact-grid1-left1">
                <span className="glyphicon glyphicon-home" aria-hidden="true" />
                <h4>Looking For Address</h4>
                <ul>
                  <li>Address: Bokaro,Jharkhand</li>
                  
                </ul>
              </div>
            </div>
            <div className="clearfix"> </div>
          </div>
          <div className="col-md-6 mail_right">
            <form action="#" method="post">
              <input
                type="text"
                name="Name"
                defaultValue="Name"
                onfocus="this.value = '';"
                onblur="if (this.value == '') {this.value = 'Name';}"
                required
              />
              <input
                type="email"
                name="Email"
                defaultValue="Email"
                onfocus="this.value = '';"
                onblur="if (this.value == '') {this.value = 'Email';}"
                required
              />
              <input
                type="text"
                name="Mobile number"
                defaultValue="Mobile number"
                onfocus="this.value = '';"
                onblur="if (this.value == '') {this.value = 'Mobile number';}"
                required
              />
              <textarea
                name="Message..."
                onfocus="this.value = '';"
                onblur="if (this.value == '') {this.value = 'Message...';}"
                required
                defaultValue={"Message..."}
              />
              <input type="submit" defaultValue="Send" />
            </form>
          </div>
          <div className="clearfix" />
        </div>
        <div className="copy_right text-center">
         
          <ul className="social-icons two">
            <li>
              <a href="index.html#"> </a>
            </li>
            <li>
              <a href="index.html#" className="fb">
                {" "}
              </a>
            </li>
            <li>
              <a href="index.html#" className="in">
                {" "}
              </a>
            </li>
            <li>
              <a href="index.html#" className="dott">
                {" "}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContactMe;
