import Header from "./component/Header";
import About from "./component/About";
import Services from "./component/Services";
import Experience from "./component/Experience";
import Portfolio from "./component/Portfolio";
import Blogs from "./component/Blogs";
import ContactMe from "./component/ContactMe";

function App() {
  return (
    <div>
      <Header/>
      <About />
      <Services />
      <Experience />
      <Portfolio />
      <Blogs />
      <ContactMe />
    </div>
  );
}

export default App;
