import React from 'react';
 
const Portfolio=()=>{
   
    return (
      <div className="portfolio" id="port">
      {/*-728x90-*/}
      <div className="service-head text-center">
        <h4>MY WORKS</h4>
        <h3>MY <span>PORTFOLIO</span></h3>
        <span className="border" />
      </div>
      <div className="portfolio-grids">
        <div className="sap_tabs">
          <div id="horizontalTab" style={{display: 'block', width: '100%', margin: '0px'}}>
          
            <div className="resp-tabs-container">
              <div className="tab-1 " aria-labelledby="tab_item-0">
              <div className="col-md-3 team-gd yes_marg ">
                  <a href="https://play.google.com/store/apps/details?id=com.mymtn.app" className="portfolio-link b-link-diagonal b-animate-go" data-toggle="modal"><img src="images/udxp.png" alt="udxp" />
                  </a>
                </div>
                <div className="col-md-3 team-gd ">
              

             
                  <a  href="https://www.acadprime.com/" className="portfolio-link b-link-diagonal b-animate-go" data-toggle="modal"><img src="images/acadprime.png" alt="acadprime" />
                  </a>
                  
                </div>
                <div className="col-md-3 team-gd yes_marg ">
                  <a href="https://www.gobrightline.com/" className="portfolio-link b-link-diagonal b-animate-go" data-toggle="modal"><img src="images/brightline.png" alt="brightline" />
                  </a>
                </div>
                <div className="col-md-3 team-gd ">
                  <a href="https://easywayglobal.in/" className="portfolio-link b-link-diagonal b-animate-go" data-toggle="modal"><img src="images/easyway.png" alt="easyway" />
                  </a>
                </div>
                <div className="col-md-3 team-gd ">
                  <a href="https://careerfoundation.org.in/" className="portfolio-link b-link-diagonal b-animate-go" data-toggle="modal"><img src="images/careerfoundation.png" className="img-responsive" alt="careerfoundation" />
                  </a>
                </div>
                
                <div className="col-md-3 team-gd ">
                  <a href="https://play.google.com/store/apps/details?id=com.careerfoundation" className="portfolio-link b-link-diagonal b-animate-go" data-toggle="modal"><img src="images/careerfoundationapp.png"    alt="careerfoundation app" />
                  </a>
                </div>
                <div className="col-md-3 team-gd yes_marg ">
                  <a href="/" className="portfolio-link b-link-diagonal b-animate-go" data-toggle="modal"><img src="images/easywayapp.png" alt="easywayapp" />
                  </a>
                </div>
               
                <div className="col-md-3 team-gd yes_marg ">
                  <a href="https://yaldafresh.com/" className="portfolio-link b-link-diagonal b-animate-go" data-toggle="modal"><img src="images/yaldafresh.png" alt="yaldafresh" />
                  </a>
                </div>
                
                <div className="clearfix" />
              </div>
              
            
            </div>
          </div>
        </div>
      </div>
    </div>
 
      );
}
export default Portfolio;