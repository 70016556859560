  const Blogs=()=>{
return(
  <>
  <div className="blog" id="blogs">
  <div className="container">
    <div className="service-head text-center">
      <h4>PROJECT DETAILS</h4>
      <h3>MY <span>MAJOR PROJECTS</span></h3>
      <span className="border one" />
    </div>
    <div className="news-grid w3l-agile">
      <div className="col-md-6 news-img">
        <a href="/"  > <img src="images/udxp.png" alt=" " className="img-responsive" /></a>
      </div>
      <div className="col-md-6 news-text">
        <h3> <a href="/" >Large Telco Company Mobile Application  (NodeJS)</a></h3>
        
        <p>The project objective is to create a mobile platform for COMVIVA who is investing in a platform for one of its Telco companies across  22 countries in Africa to enable them to sell their virtual (recharge bundles) and physical assets (mobile  phones, modems, etc).  The platform was in ReactJS. Node JS was used to build mock Telco APIs.
          <br/>
          <strong>
         Technologies : - NodeJS, Mysql, MongDB,  Flutterwave and Twilio integration
         </strong>
        <br/>
    <strong>
      Developing APIs in Node JS,Integration with 3rd party payment gateway (FLutterwave),Integration with SMS service provide (Twilio).
      </strong>

           </p>
     
      </div>
      <div className="clearfix" />
    </div>
    <div className="news-grid w3l-agile">
      <div className="col-md-6 news-img">
        <a href="/"  > <img src="images/careerfoundationapp.png" alt=" " className="img-responsive" /></a>
      </div>
      <div className="col-md-6 news-text">
        <h3> <a href="/" >Careerfoundation (App and website,app in react native and website in codeiginiter)</a></h3>
        
        <p>This is an online education website and mobile app. In this platform students can register through websites or mobile applications and then purchase courses. They can learn the courses by watching videos. Online payment gateway used is instamojo,(Razorpay).This application is used for government job preparation.
The application was built in ReactNative , Api is in codeiginiter and database is mysql.

          <br/>
          <strong>
         Technologies : - React Native for mobile app,Codeigniter for api and website
         </strong>
        <br/>
    <strong>
      Developing APIs in codeiginiter,Integration with 3rd party payment (Razorpay),Design and Developing website also(Full Stack).
      </strong>

           </p>
     
      </div>
      <div className="clearfix" />
    </div>
    <div className="news-grid w3l-agile">
      <div className="col-md-6 news-img">
        <a href="/"  > <img src="images/easyway.png" alt=" " className="img-responsive" /></a>
      </div>
      <div className="col-md-6 news-text">
        <h3> <a href="/" >Easyway Global (App and website,app in react native and website in laravel 8)</a></h3>
        
        <p>This is an online education website and mobile app. In this platform students can register through websites or mobile applications and then purchase courses. They can learn the courses by watching videos. Online payment gateway used is Razorpay.This application is used for government job preparation specialy ITI.
The application was built in ReactNative , Api is in Laravel 8 and database is mysql.


          <br/>
          <strong>
          Technologies : - React Native for mobile app,Laravel 8 for api and website
         </strong>
        <br/>
    <strong>
    Developing APIs in Laravel 8,Integration with 3rd party payment (Razorpay),Design and Developing website also(Full Stack).
      </strong>

           </p>
     
      </div>
      <div className="clearfix" />
    </div>

    <div className="news-grid w3l-agile">
      <div className="col-md-6 news-img">
        <a href="/"  > <img src="images/acadprime.png" alt=" " className="img-responsive" /></a>
      </div>
      <div className="col-md-6 news-text">
        <h3> <a href="/" >acadprime (Website using codeiginiter 3)</a></h3>
        
        <p>An educational Application Implemented zoom and bigblue for video confrencing and classes.Razorpay payment gateway.In this application have admin,teacher,student,and parent pannel.
          parent can see the child progress and check what he is doing.
          Admin can create batch and then assign to teacher and students,teacher can take attendance and can take classes.
          <br/>
          <strong>
          Technologies : - Codeigniter 3,html,css,bootsrap,jquery,ajax
         </strong>
        <br/>
    <strong>
  Role - All the website including frontend and backend both (Full Stack).
      </strong>

           </p>
     
      </div>
      <div className="clearfix" />
    </div>
    <div className="col-md-12 news-text">
      <p>I have developed more than 80 projects and in 7 years journey done many complicated applications using php,node,react,react native,mysql,mongodb,neo4j.
   <br/>
   <strong> Royal Challengers Bangalore (Indian Premier League, Cricket Franchise) (NodeJS)</strong>
   <br/>
RCB App is a large App with social, content, gaming, ed-tech, fitness, eCommerce, NFTs, lifestyle features.  The Application is built in ReactJS and I assisted in managing and building the APIs.
Technology Highlights
NodeJS, MySql, Neo4J.
Responsibilities:
Ticketing Module
Load Testing
Database testing.


      </p>
      <p>
      <strong>Hustle (In NodeJS)</strong>
      <br/>
RCB Hustle is an online fitness platform providing live fitness classes over video conferencing.
The state-of-the-art video conferencing system for trainers and trainees has been completely custom-built.
The platform also includes engagement features such as social feeds, buddy system and leaderboard. 
Technology Highlights
NodeJs, MySql.
Responsibilities:
Trainer & DIY  modules
Database testing.

      </p>
      <p>
     <strong> wisehealths.isoftms.in (Codeigniter)</strong>
      <br/>
This is a Pathology application where the admin can add franchise.  franchisees can login through their panel and they are able to add users. Users can login through their panel and generate invoices and payments. Technology Highlights
PHP 7.2, CodeIgniter, MySQL, Ajax, Jquery, HTML, CSS
Responsibilities:
Development and testing .
Whole backend functionality and database testing.

      </p>
      <p>
     <strong> Ecommerce Application (Codeigniter)</strong>
     <br/>
 yaldafresh.com, almusallastationery.com, myshopking.com etc. all have mobile apps. Worked on API section and full backend section.
Technology Highlights
Codeigniter, Ajax, Html, Jquery, Css.
Responsibilities:
Whole backend and database management
Creating API for mobile application

      </p>
      </div>
  </div>
</div>
 
</>
    
);
}

export default Blogs;