import React from "react";
const About = () => {
  return (
    <div id="about" className="about">
      <div className="col-md-6 about-left">
        <div id="" className="">
          <div className="item">
            <div className="about-left-grid">
              <h2>
                Hi! I'm <span>Rishi Kant Tiwary (Full Stack Developer)</span>
              </h2>
              <p>
               I have 7 years of experience in software developement.I have done many complicated application single handly.
              </p>
              <h2>Able to work on these technologies</h2>
              <p>
                <strong>
              Core PHP,
CodeIgniter, Laravel,React, Next Js,Node.js,
<br/>
React Native,Flutter,MySql,Workbench,MongoDB
Neo4j,ORM(sequelize),AWS(EC2,S3),Redis,Elastic Search
<br/>
HTML,CSS,Jquery,Ajax.
</strong>
              </p>
              <ul>
                <li>
                  <a className="a-btn-a scroll" href="/#port">
                    My Work
                  </a>
                </li>
                <li>
                  <a className="a-btn-h scroll" href="/#contact">
                    Contact Me
                  </a>
                </li>
              </ul>
            </div>
          </div>
          
          
        </div>
      </div>
      <div className="col-md-6 about-right"></div>
      <div className="clearfix"> </div>

      {/* Feedback */}
    </div>
  );
};

export default About;
