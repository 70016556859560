const Header = () => {
  return (
    <div id="home" className="header">
    <div className="container">
      {/* top-hedader */}
      <div className="top-header">
        {/* /logo */}
        {/*top-nav--*/}
        <div className="top-nav">
          <div className="navigation">
            <div className="logo">
              <h1><a href="/"><span>RISHI</span></a></h1>
            </div>
            <div className="navigation-right">
              <span className="menu"><img src="images/menu.png" alt=" " /></span>
              <nav className="link-effect-3" id="link-effect-3">
                <ul className="nav1 nav nav-wil">
                  <li className="active"><a data-hover="Home" href="/">Home</a></li>
                  <li><a className="scroll" data-hover="About" href="/#about">About</a></li>
                  <li><a className="scroll" data-hover="Services" href="/#services">Services</a></li>
                  <li><a className="scroll" data-hover="Experience" href="/#work">Experience</a></li>
                  <li><a className="scroll" data-hover="Portfolio" href="/#port">Portfolio</a></li>
                  <li><a className="scroll" data-hover="Blog" href="/#blogs">Blog</a></li>
                  <li><a className="scroll" data-hover="Contact" href="/#contact">Contact</a></li>
                </ul>
              </nav>
              {/* script-for-menu */}
              {/* /script-for-menu */}
            </div>
            <div className="clearfix" />
          </div>
          {/* /top-hedader */}
        </div>
        <div className="banner-info">
          <div className="col-md-7 header-right">
            <h1>Hi !</h1>
            <h6>FULL STACK DEVELOPER</h6>
            <ul className="address">
              <li>
                <ul className="address-text">
                  <li><b>NAME</b></li>
                  <li>I'M RISHI KANT TIWARY</li>
                </ul>
              </li>
              <li>
                <ul className="address-text">
                  <li><b>D.O.B</b></li>
                  <li>11-01-1993</li>
                </ul>
              </li>
              <li>
                <ul className="address-text">
                  <li><b>PHONE </b></li>
                  <li>+917979977808</li>
                </ul>
              </li>
              <li>
                <ul className="address-text">
                  <li><b>ADDRESS </b></li>
                  <li>HOSIR BRAHMAN TOLA,PS-GOMIA,DIST-BOKARO</li>
                </ul>
              </li>
              <li>
                <ul className="address-text">
                  <li><b>E-MAIL </b></li>
                  <li>
                    <a href="mailto:rishitiwary694076@gmail.com"> <span className="__cf_email__">rishitiwary694076@gmail.com</span></a>
                  </li>
                </ul>
              </li>
              <li>
                <ul className="address-text">
                  <li><b>LANGUAGE</b></li>
                  <li>HINDI,ENGLISH</li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-md-5 header-left">
            <img src="images/image1.jpeg" alt="" />
          </div>
          <div className="clearfix"> </div>
        </div>
      </div>
    </div>
  </div>

  );
};
export default Header;
