import React from 'react';
const Experience=()=>{
    return(
      <div id="work" className="work">
        <div className="container">
          <div className="service-head text-center">
            <h4>WHAT I DID</h4>
            <h3>MY <span>EXPERIENCE</span></h3>
            <span className="border one" />
          </div>
          <div className="time-main w3l-agile">
            <div className="col-md-6 year-info">
              <ul className="year">
                <li>Nov 2021 to Present</li>
                <li>Jan 2020 to Nov 2021</li>
                <li>August 2017 to 2020</li>
                <li>June 2016 to August 2017</li>
                
                <div className="clearfix" />
              </ul>
            </div>
            <ul className="col-md-6 timeline">
              <li>
                <div className="timeline-badge info"><i className="glyphicon glyphicon-briefcase" /></div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4 className="timeline-title">Unimity Solution Pvt Ltd.</h4>
                  </div>
                  <div className="timeline-body">
                    <p>Working as a Analyst Programmer.(Node,Mysql,ORM(Sequelize),
                      <br/>Workbench,Neo4j,React Js,Next Js,Reat Native,S3). </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="timeline-badge primary"><i className="glyphicon glyphicon-briefcase" /></div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4 className="timeline-title">Isoftms Pvt Ltd</h4>
                  </div>
                  <div className="timeline-body">
                    <p>Worked as a senior software engineer.(Codeigniter,Laravel,Html,CSS,Jquery,<br/>Bootstrap,Ajax,Mysql)</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="timeline-badge danger"><i className="glyphicon glyphicon-briefcase" /></div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4 className="timeline-title">Airisfoundation</h4>
                  </div>
                  <div className="timeline-body">
                    <p>Worked as a senior software engineer.(Core Php,Codeigniter,Laravel,Html,CSS,<br/>Bootstrap,Jquery,Ajax,Angular) </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="timeline-badge success"><i className="glyphicon glyphicon-briefcase" /></div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4 className="timeline-title">Hypemax Pvt Ltd</h4>
                  </div>
                  <div className="timeline-body">
                    <p>Worked as Junior software developer.(Core php,Html,css,bootstrap,jquery)</p>
                  </div>
                </div>
              </li>
              
            </ul>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    
      );
    
}
export default Experience;